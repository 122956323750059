export const enterFullScreen = (selector) => {
  const docElm = document.querySelector(selector);
  if (docElm.requestFullscreen) return docElm.requestFullscreen();
  // W3C
  else if (docElm.mozRequestFullScreen) return docElm.mozRequestFullScreen();
  // FireFox
  else if (docElm.webkitRequestFullScreen)
    return docElm.webkitRequestFullScreen(); // Chrome等
};

export const exitFullScreen = () => {
  if (document.exitFullscreen) return document.exitFullscreen();
  /*   else if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
  else if (document.webkitCancelFullScreen)
    return document.webkitCancelFullScreen(); */
};

export const handleFullScreenChange = (selector, callback) =>
  document
    .querySelector(selector)
    ?.addEventListener("fullscreenchange", function () {
      if (document.fullscreenElement) {
        callback(true);
      } else {
        callback(false);
      }
    });
