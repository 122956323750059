<template>
  <div ref="SimMind" v-contextmenu:contextmenu class="SimMind">
    <BottomToolBar
      @mapEvent="handleMapEvent"
      v-model:fullscreen="fullscreen"
      v-model:lockStatus="lockStatus"
      :zoom="zoom"
    />
    <div id="sim-tree" class="sim-tree"></div>
    <v-contextmenu ref="contextmenu">
      <ContextMenuContainer
        :selectedNode="selectedNode"
        @contextEvent="handleContextEvent"
        @addModule="(name) => $emit('addModule', name)"
      />
    </v-contextmenu>
  </div>
</template>

<script>
import treeInit from "./main";
import BottomToolBar from "./parts/BottomToolBar.vue";
import ContextMenuContainer from "./parts/ContextMenuContainer.vue";
import { directive, Contextmenu } from "v-contextmenu";
import { defineComponent } from "vue";
let XMIND;
export default defineComponent({
  name: "MindmapCore",
  components: {
    BottomToolBar,
    ContextMenuContainer,
    [Contextmenu.name]: Contextmenu,
  },
  props: {
    root: {
      type: Object,
      required: true,
    },
  },
  emits: ["editNode", "deleteNode", "addNode", "addModule"],
  directives: {
    contextmenu: directive,
  },
  data() {
    return {
      zoom: 100,
      fullscreen: false,
      lockStatus: false,
      selectedNode: null,
    };
  },

  watch: {
    root: {
      immediate: false,
      deep: true,
      handler(data) {
        if (data) XMIND.setInitData(data);
      },
    },
    lockStatus(val) {
      if (val) {
        XMIND.execCommand("Hand");
      } else {
        XMIND.execCommand("Hand", false);
      }
    },
  },
  methods: {
    handleMapEvent(e) {
      if (e === "VIEW_REDUCE") {
        let state = XMIND.queryCommandState("ZoomOut");

        if (state === 0) {
          XMIND.execCommand("ZoomOut");
        }
      }
      if (e === "VIEW_PLUS") {
        let state = XMIND.queryCommandState("ZoomIn");
        if (state === 0) {
          XMIND.execCommand("ZoomIn");
        }
      }
    },
    handleContextEvent({ type, payload }) {
      switch (type) {
        case "ADD_NODE":
          console.log({ payload });

          this.$emit("addNode", payload);
          break;
        case "FOCUS_NODE":
          XMIND.execCommand("Camera", XMIND.getSelectedNode(), 10);
          break;
        case "EDIT_NODE":
          this.$emit("editNode", XMIND.getSelectedNode().data);
          break;
        case "DELETE_NODE":
          this.$emit("deleteNode", XMIND.getSelectedNode().data);
          break;
        default:
          break;
      }
    },
    findSelectNode() {
      this.selectedNode = XMIND.getSelectedNode();
    },
  },

  mounted() {
    XMIND = treeInit("#sim-tree");
    XMIND.setInitData(this.root);
    if (this.theme && this.theme !== "") {
      XMIND.execCommand("Theme", this.theme);
    }
    if (this.lockStatus) {
      XMIND.execCommand("Hand");
    }

    //Event Listners
    XMIND.on("contextmenu", this.findSelectNode);
    XMIND.on("zoom", (e) => (this.zoom = e.zoom));
  },
  beforeUnmount() {
    XMIND.off("contextmenu", this.findSelectNode);
    //XMIND.destroy()
    document.querySelector("#sim-tree svg").remove();
    XMIND = null;
  },
});
</script>

<style lang="scss" scoped>
.SimMind {
  position: relative;
  width: 100%;
  height: 100%;
}
.sim-tree {
  width: 100%;
  height: 100%;
}
</style>
