import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6039e504"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "group-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_v_contextmenu_item = _resolveComponent("v-contextmenu-item")!
  const _component_v_contextmenu_group = _resolveComponent("v-contextmenu-group")!
  const _component_v_contextmenu_submenu = _resolveComponent("v-contextmenu-submenu")!
  const _component_v_contextmenu_divider = _resolveComponent("v-contextmenu-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.possibleChildNodes.length > 0)
      ? (_openBlock(), _createBlock(_component_v_contextmenu_group, {
          key: 0,
          title: _ctx.$t('mindmap.addChildNode')
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.possibleChildNodes, (node) => {
              return (_openBlock(), _createBlock(_component_v_contextmenu_item, {
                key: node,
                onClick: ($event: any) => (_ctx.emitAddNodeClick(node))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_inline_svg, {
                      src: `svg/icons/node_btn_${node.toLowerCase()}.svg`
                    }, null, 8, ["src"]),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(node.toLowerCase())), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_contextmenu_submenu, {
      title: _ctx.$t('mindmap.blockPresets')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_contextmenu_item, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addModule('user')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("mindmap.userModule")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_contextmenu_item, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addModule('permission')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("mindmap.permissionModule")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_v_contextmenu_item, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addModule('vacation')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("mindmap.vacationModule")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_v_contextmenu_divider),
    _createVNode(_component_v_contextmenu_item, {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('contextEvent', { type: 'DELETE_NODE' }))),
      disabled: _ctx.possibleChildNodes.length == 0
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("delete")), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_v_contextmenu_item, {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('contextEvent', { type: 'EDIT_NODE' }))),
      disabled: _ctx.possibleChildNodes.length == 0 || _ctx.selectedNode?.data?.id == 1
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("mindmap.edit")), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_v_contextmenu_item, {
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('contextEvent', { type: 'EDIT_NODE' }))),
      disabled: _ctx.possibleChildNodes.length == 0 || _ctx.selectedNode?.data?.id == 1
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("mindmap.details")), 1)
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_v_contextmenu_divider),
    _createVNode(_component_v_contextmenu_item, {
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.focusNode(_ctx.node))),
      disabled: _ctx.possibleChildNodes.length == 0
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("mindmap.focus")), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ], 64))
}