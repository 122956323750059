
import { computed, defineComponent } from "vue";
import "v-contextmenu/dist/themes/default.css";
import {
  version,
  ContextmenuItem,
  ContextmenuDivider,
  ContextmenuSubmenu,
  ContextmenuGroup,
} from "v-contextmenu";
import { useStore } from "vuex";

console.log("VContextmenu version:", version);
export default defineComponent({
  name: "ExampleSimple",
  emits: ["contextEvent", "addModule"],
  components: {
    [ContextmenuItem.name]: ContextmenuItem,
    [ContextmenuDivider.name]: ContextmenuDivider,
    [ContextmenuSubmenu.name]: ContextmenuSubmenu,
    [ContextmenuGroup.name]: ContextmenuGroup,
  },
  props: {
    selectedNode: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  setup(props, { emit }) {
    const store = useStore();
    const possibleChildNodes = computed(() => {
      if (!props.selectedNode) return [];
      let { type } = props.selectedNode.data;
      return store.getters.getNodeTypeById(type)?.metadata.possibleChild || [];
    });

    const emitAddNodeClick = (type) => {
      emit("contextEvent", {
        type: "ADD_NODE",
        payload: {
          type,
          parentId: props.selectedNode.data.id,
        },
      });
    };
    const focusNode = () => {
      emit("contextEvent", {
        type: "FOCUS_NODE",
        payload: {},
      });
    };

    const addModule = (name) => {
      emit("addModule", name);
    };
    return { emitAddNodeClick, focusNode, addModule, possibleChildNodes };
  },
  methods: {},
});
