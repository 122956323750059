import "kity";
import "kityminder-core";
const config = {
  nodeBackground: "rgba(245, 245, 245, 1)",
  nodeColor: "#7239EA",
};
function treeInit(el) {
  // eslint-disable-next-line no-undef
  kityminder.Theme.register("custom", {
    "root-color": config.nodeColor,
    "root-background": config.nodeBackground,
    "root-stroke": config.nodeColor,
    "root-font-size": 12,
    "root-padding": [20, 25, 20],
    "root-margin": [30, 100],
    "root-radius": 12,
    "root-space": 10,
    "root-shadow": "rgba(0, 0, 0, 0)",

    "main-color": config.nodeColor,
    "main-background": config.nodeBackground,
    "main-stroke": config.nodeColor,
    "main-font-size": 12,
    "main-padding": [20, 25, 20],
    "main-margin": [30, 100],
    "main-radius": 12,
    "main-space": 500,
    "main-shadow": "rgba(0, 0, 0, 0)",

    "sub-color": config.nodeColor,
    "sub-background": config.nodeBackground,
    "sub-stroke": config.nodeColor,
    "sub-font-size": 12,
    "sub-padding": [20, 25, 20],
    "sub-margin": [15, 20],
    "sub-tree-margin": 30,
    "sub-radius": 12,
    "sub-space": 5,

    "connect-color": config.nodeColor,
    "connect-width": 1.5,
    "main-connect-width": 2,
    "connect-radius": 12,

    "selected-background": "#7239EA",
    "selected-stroke": "#7239EA",
    "selected-color": "white",

    "marquee-background": "rgba(255,255,255,.3)",
    "marquee-stroke": "#7239EA",

    "drop-hint-color": "#7239EA",
    "sub-drop-hint-width": 4,
    "main-drop-hint-width": 4,
    "root-drop-hint-width": 4,

    "order-hint-area-color": "rgba(0, 255, 0, .5)",
    "order-hint-path-color": "#0f0",
    "order-hint-path-width": 2,

    "text-selection-color": "rgb(27,171,255)",
    "line-height": 1.5,
  });
  // eslint-disable-next-line no-undef
  let minder = new kityminder.Minder({
    Vue: "",
    renderTo: el,
  });
  window.minderInstance = minder;
  minder.setDefaultOptions({
    defaultTheme: "custom",
  });

  minder.setInitData = function (data) {
    minder.importJson({
      root: data,
      template: "default",
    });
  };
  return minder;
}
export default treeInit;
