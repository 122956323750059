<template>
  <div class="mindmap-toolbar">
    <div class="box">
      <button class="box-item" @click="toggleLockStatus">
        <inline-svg
          height="26px"
          :src="`svg/icons/${lockStatus ? 'cursor-hand' : 'cursor-arrow'}.svg`"
        ></inline-svg>
      </button>
    </div>
    <div class="box">
      <button class="box-item" @click="toggleFullscreen">
        <inline-svg
          height="26px"
          :src="`svg/icons/${fullscreen ? 'compress' : 'expand'}.svg`"
        ></inline-svg>
      </button>
    </div>

    <div class="box">
      <template v-for="item in zoomBoxItems" :key="item.action">
        <button class="box-item" v-if="item.action" @click="headleClick(item)">
          <inline-svg v-if="item.icon" :src="item.icon"> </inline-svg>
        </button>
        <div class="box-item" v-else-if="item.text">{{ zoom }}%</div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  enterFullScreen,
  exitFullScreen,
  handleFullScreenChange,
} from "@/core/plugins/fullscreen";
export default {
  props: {
    zoom: {
      type: Number,
      default: 100,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    lockStatus: {
      type: Boolean,
    },
  },
  emits: ["update:fullscreen", "update:lockStatus"],
  mounted() {
    handleFullScreenChange("#mindmap", (val) =>
      this.$emit("update:fullscreen", val)
    );
  },
  data() {
    return {
      zoomBoxItems: [
        { icon: "svg/mindmap/headle/minus.svg", action: "VIEW_REDUCE" },
        { text: this.zoom },
        { icon: "svg/mindmap/headle/plus.svg", action: "VIEW_PLUS" },
      ],
    };
  },
  methods: {
    headleClick(item) {
      if (!item.action) return;
      this.$emit("mapEvent", item.action);
    },
    toggleFullscreen() {
      this.fullscreen ? exitFullScreen() : enterFullScreen("#mindmap");
    },
    toggleLockStatus() {
      console.log({ lockStatus: this.lockStatus });
      this.$emit("update:lockStatus", !this.lockStatus);
    },
  },
};
</script>

<style lang="scss" scoped>
.mindmap-toolbar {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  .box {
    display: flex;
    margin-left: 15px;
    button.box-item:hover {
      background: white;
      cursor: pointer;
    }
    .box-item {
      transition: all ease 0.3s;
      height: 36px;
      width: 42px;
      background: #b5b5c3;
      border: unset;
      font-weight: 500;
      font-size: 10px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        border-top-right-radius: 0.475rem;
        border-bottom-right-radius: 0.475rem;
      }
      &:first-child {
        border-top-left-radius: 0.475rem;
        border-bottom-left-radius: 0.475rem;
      }
    }
  }
}
</style>
